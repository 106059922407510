<script>
import { mapActions, mapGetters } from 'vuex'
import { enableBodyScroll } from 'body-scroll-lock'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import FilterSearch from '@/components/FilterSearch'
import Order from '@/components/Order'
import ModalNewOrder from '@/components/ModalNewOrder'
import ModalCheckoutOrder from '@/components/ModalCheckoutOrder'
import ContentLoading from '@/components/ContentLoading'
import routes from '@/router/index'
import ModalSideBar from '@/components/ModalSideBar.vue'
import { format } from 'date-fns'
import NoResultsMessage from '@/components/NoResultsMessage.vue'

export default {
  name: 'Orders',

  components: {
    Action,
    Icon,
    FilterSearch,
    Order,
    ModalNewOrder,
    ModalCheckoutOrder,
    ContentLoading,
    ModalSideBar,
    NoResultsMessage,
  },

  data () {
    return {
      loading: false,
      loadingOrders: false,
      showFilter: false,
      showModalNewOrder: false,
      showModalCheckoutOrder: false,
      selectedOrder: null,
      selectedFilter: null,
      search: null,
      view: 'block',
      startAnimation: false,
      selectedLastDay: 30, // quantidade de dias que inicialmente irá buscar os pedidos
      maxRecords: 30, // quantidade de pedidos
      pageNumber: 1,
      dateStart: null,
      dateEnd: null,
    }
  },

  computed: {
    ...mapGetters('orders', ['orders', 'total', 'totalInProgress', 'totalFinished', 'totalRecords']),

    filters () {
      return [
        {
          label: `Todos (${this.total})`,
          value: null,
        },
        {
          label: `Em andamento (${this.totalInProgress})`,
          value: 'Em andamento',
        },
        {
          label: `Finalizados (${this.totalFinished})`,
          value: 'Finalizado',
        }
      ]
    },

    orderFiltered () {
      let tempOrders = this.orders
      if (this.selectedFilter) {
        tempOrders = this.orders.filter(order => order.status === this.selectedFilter)
      }
      if (this.search) {
        tempOrders = this.orders.filter(order => order.name.toLowerCase().includes(this.search.toLowerCase()))
      }
      return tempOrders
    },

    orderFilteredTotal () {
      return this.orderFiltered.reduce((value, item) => {
        return value + item.total
      }, 0)
    },

    totalPage () {
      return Math.ceil(this.totalRecords / this.maxRecords)
    },

  },

  mounted () {
    this.dateFilter()
    this.loading = true
    enableBodyScroll()
    this.getAllOrders({ dateStart: this.dateStart, dateEnd: this.dateEnd, maxRecords: this.maxRecords, pageNumber: this.pageNumber })
      .then(() => {
        this.loading = false
        this.startAnimation = true
      })
  },

  methods: {
    ...mapActions('orders', ['getAllOrders', 'getMoreOrders']),

    handleOrderSearch (searchText) {
      this.search = searchText
    },

    handleOrderFilter (filter) {
      this.selectedFilter = filter
    },

    handleOrderView (view) {
      this.startAnimation = false
      this.view = view
      this.$nextTick(() => {
        this.startAnimation = true
      })
    },

    handleCheckoutOrder (order) {
      this.selectedOrder = order
      this.showModalCheckoutOrder = true
    },

    handleLastDays ({ selectedLastDay, dateStart, dateEnd, pageNumber }) {
      this.selectedLastDay = selectedLastDay
      this.dateStart = dateStart
      this.dateEnd = dateEnd
      this.pageNumber = pageNumber

      this.getAllOrders({
        dateStart,
        dateEnd,
        maxRecords: this.maxRecords,
        pageNumber: this.pageNumber,
      })
    },

    dateFilter () {
      this.dateStart = format(this.$refs.sideBar.range.start, 'dd.MM.yyyy')
      this.dateEnd = format(this.$refs.sideBar.range.end, 'dd.MM.yyyy')
    },

    async loadMoreOrders () {
      this.pageNumber++
      this.loadingOrders = true
      await this.getMoreOrders({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        maxRecords: this.maxRecords,
        pageNumber: this.pageNumber,
      }).then(() => {
        this.loadingOrders = false
      })
    },

    newOrder () {
      this.showModalNewOrder = true
    },

    handleUpdateOrders () {
      this.getAllOrders({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        maxRecords: this.maxRecords,
        pageNumber: this.pageNumber,
      })
    },

    openFilter () {
      this.showFilter = !this.showFilter
    },

    closeModal () {
      this.showFilter = false
      if (routes.currentRoute.name !== 'orders') {
        return routes.push({ name: 'orders' })
      }
    },
  },

}
</script>

<template>
  <div :class="$classes['order']">
    <div :class="$classes['header']">
      <span :class="$classes['header-title']">Gerenciar carrinho</span>
      <action variant="outlined" v-if="!$media.mobile" color="secondary" :class="$classes['header-btn']"
        @click="newOrder">
        <icon name="clipboard-list" btnIcon />Iniciar Novo Pedido
      </action>
      <action variant="outlined" v-else mobile color="secondary" :class="$classes['header-btn']" @click="newOrder">
        <icon name="clipboard-list" btnIcon />Novo Pedido
      </action>
    </div>
    <div :class="$classes['order-filter']">
      <filter-search :class="$classes['order-filter-search']" placeholder="Pesquisar pedido" :filters="filters"
        @search="handleOrderSearch" @filter="handleOrderFilter" @view="handleOrderView" />
      <div :class="$classes['order-total']" v-if="selectedFilter === 'Em andamento'">
        <span :class="$classes['order-total-label']">Total dos pedidos em andamento:</span>
        <span :class="$classes['order-total-value']">{{ orderFilteredTotal | currency }}</span>
      </div>
      <div :class="$classes['order-button-wrapper']">
        <action :class="$classes['order-button-filter']">
          <span @click="openFilter" :class="$classes['menu-nav-icon-wrapper']">
            <icon :name="'filter-slim'" :class="$classes['menu-nav-icon']" />
            Filtro
          </span>
        </action>
        <action :class="{ [$classes['order-button-filter']]: true }">
          <span v-if="selectedLastDay !== 0" @click="openFilter" :class="$classes['menu-nav-icon-wrapper']">
            {{ selectedLastDay }} últimos dias
          </span>
          <span v-else @click="openFilter" :class="$classes['menu-nav-icon-wrapper']">
            Hoje
          </span>
        </action>
      </div>
    </div>
    <modal-side-bar :active.sync="showFilter" ref="sideBar" :lastDay="selectedLastDay" @changeDay="handleLastDays"
      @close="closeModal"></modal-side-bar>
    <div :class="[$classes['order-list-content']]">
      <content-loading v-if="loading" />
      <div v-else-if="orders != 0" :class="[$classes['order-list'], {
        [$classes['order-list-line']]: view === 'line'
      }]">
        <div :class="$classes['order-list-header']" v-if="view === 'line'">
          <span :class="$classes['order-list-header-title']">Pedido</span>
          <span :class="$classes['order-list-header-title']">Ult. Atualização</span>
          <span :class="$classes['order-list-header-title']">Total</span>
          <span :class="$classes['order-list-header-title']">Status</span>
        </div>
        <template v-for="(order, orderIndex) in orderFiltered">
          <order :class="{
            [$classes['order-animate']]: startAnimation
          }" :style="{ '--animation-order': orderIndex + 1 }" :key="order.id" :id="order.id" :name="order.name"
            :total="order.total" :status="order.status" :status_web="order.status_web" :updated="order.updated"
            :view-type="view" @finish="handleCheckoutOrder(order)" />
        </template>
      </div>
      <no-results-message v-else :title="'Nenhum pedido foi encontrado.'"
        :message="'Tente ajustar os filtros de pesquisa ou expandir o intervalo de datas.'" />
      <content-loading v-if="loadingOrders" />
      <div v-else-if="totalPage > pageNumber" :class="$classes['order-list-footer']">
        <action variant="outlined" color="secondary" :class="$classes['header-btn']" @click="loadMoreOrders">
          Carregar mais pedidos
        </action>
      </div>
    </div>

    <modal-new-order :active.sync="showModalNewOrder" @updateOrders="handleUpdateOrders" />

    <modal-checkout-order :active.sync="showModalCheckoutOrder" :order="selectedOrder"
      @updateOrders="handleUpdateOrders" />
  </div>
</template>

<style module>
.order {
  padding: 25px 25px 45px 45px;
}

.order-button-filter {
  margin-left: 15px;
  border-radius: 30px;
  font-weight: 500;
  color: #292929;
  background-color: #ffffff;
  border: 1px solid #8f8b8b20;
}

.order-button-filter:hover {
  color: #fff;
  background-color: #292929;
}

.order-button-filter.active {
  color: #fff;
  background-color: #292929;
  /* border: 1px solid #8f8b8b30; */
}

.order-button-wrapper {
  display: flex;
  margin-top: 20px;
}

.menu-nav-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  flex-shrink: 0;
  padding: 4px 16px;
  font-size: 14px;
}

.menu-nav-icon-wrapper .menu-nav-icon {
  color: transparent;
  margin-right: 2px;
  stroke: #8f8b8b;
  stroke-width: 5px;
}

.menu-list-filter {
  margin-top: 35px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vh;
  align-items: center;
}

.header-title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #292929;
}

.order-filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.order-filter-search {
  max-width: 440px;
}

.order-total {
  display: flex;
  align-items: center;
  flex: 1;
  background: rgba(31, 32, 65, 0.05);
  border-radius: 2px;
  height: 40px;
  padding: 0 20px 0 25px;
  margin-left: 15px;
}

.order-total-label {
  flex: 1;
  font-size: 12px;
  color: #A2A2A2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.order-total-value {
  font-size: 14px;
  font-weight: bold;
  color: #A2A2A2;
  letter-spacing: 0.1em;
}

.order-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 35px;
  gap: 10px;
}

.order-list-content {
  display: block;
  text-align: -webkit-center;
}

.order-list-line {
  display: block;
}

.order-list-header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  margin-bottom: 5px;
}

.order-list-header-title {
  flex: 1;
  font-size: 14px;
  color: #a2a2a2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.order-list-header-title:nth-child(2),
.order-list-header-title:nth-child(3) {
  max-width: 180px;
}

.order-list-header-title:nth-child(4) {
  max-width: 290px;
}

.order-list-footer {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.order-animate {
  animation-name: animateIn;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
  animation-delay: calc(var(--animation-order) * 50ms);
  animation-fill-mode: both;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .order {
    padding: 10px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
  }

  .header-btn {
    order: 2;
    align-self: center;
    max-width: 270px;
  }

  .header-title {
    order: 1;
    max-width: min-content;
  }

  .order-filter {
    display: block;
  }

  .order-total {
    margin-left: 0;
    margin-top: 20px;
  }
}
</style>
